// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-enviado-js": () => import("/opt/build/repo/src/pages/enviado.js" /* webpackChunkName: "component---src-pages-enviado-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produtos-js": () => import("/opt/build/repo/src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */)
}

