import React, { useReducer, createContext } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const initialState = {
  language: "pt",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "pt": {
      return {
        ...state,
        language: "pt",
      }
    }
    case "en": {
      return {
        ...state,
        language: "en",
      }
    }
    case "es":
      {
        return {
          ...state,
          language: "es",
        }
      }
      break
    default:
      throw new Error("Bad action")
  }
}

const GlobalContextPorvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextPorvider
